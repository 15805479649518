<template>
  <div class="obligationOrderAddressPage" v-if="!!$parent.datas.result.receive">
    <div class="title fontWeightBold">{{ $fanyi('收货地址') }}</div>
    <div class="addressDelivery">
      <div class="flexAndCenter addressDeliveryHeader">
        <div class="label1">{{ $fanyi('收件人') }}</div>
        <!-- <div class="label2">{{ $fanyi('公司') }}</div> -->

        <div class="label4">{{ $fanyi('手机号') }}</div>
        <div class="label5">{{ $fanyi('电子邮箱') }}</div>
        <div class="label6">{{ $fanyi('地址') }}</div>
        <div class="label7">{{ $fanyi('邮编') }}</div>
        <div class="label8">{{ $fanyi('城市') }}</div>
        <div class="label9">{{ $fanyi('州') }}</div>
      </div>
      <div class="flexAndCenter addressDeliveryFooter">
        <div class="label1">
          {{
            $parent.datas.result.receive.contacts
            ? $parent.datas.result.receive.contacts
            : '-------'
          }}
        </div>
        <!-- <div class="label2">
          {{
            $parent.datas.result.receive.company
              ? $parent.datas.result.receive.company
              : '-------'
          }}
        </div> -->
        <!-- <div class="label3">
          {{
            $parent.datas.result.receive.cnpj
              ? $parent.datas.result.receive.cnpj
              : '------'
          }}
        </div> -->
        <div class="label4">
          {{
            $parent.datas.result.receive.mobile
            ? $parent.datas.result.receive.mobile
            : '--------'
          }}
        </div>
        <div class="label5">
          {{
            $parent.datas.result.receive.email
            ? $parent.datas.result.receive.email
            : '--------'
          }}
        </div>
        <div class="label6">
          {{
            $parent.datas.result.receive.address
            ? $parent.datas.result.receive.address
            : '--------'
          }}
        </div>
        <div class="label7">
          {{
            $parent.datas.result.receive.zip_code
            ? $parent.datas.result.receive.zip_code
            : '--------'
          }}
        </div>
        <div class="label8">
          {{
            $parent.datas.result.receive.city
            ? $parent.datas.result.receive.city
            : '--------'
          }}
        </div>
        <div class="label9" style="border: none">
          {{ $fanyi($parent.datas.result.receive.state) }}
        </div>
      </div>
    </div>
    <!--  importer-->

    <!-- <div class="title fontWeightBold" style="margin-top: 30px">{{$fanyi("进货商")}}</div>
  <div class="addressDelivery">
    <div class="flexAndCenter importerGoodsHeader">
      <div class="label10">{{$fanyi("收件人")}}</div>
      <div class="label11">{{$fanyi("地址")}}</div>
      <div class="label12">{{$fanyi("邮编")}}</div>
      <div class="label13">{{$fanyi("城市")}}</div>
      <div class="label14">{{$fanyi("公司")}}</div>
      <div class="label15">{{$fanyi("电话")}}</div>
      <div class="label16">{{$fanyi("电子邮箱")}}</div>
    </div>
    <div class="flexAndCenter importerGoodsFooter">
      <div class="label10">{{$parent.datas.result.importer.contacts}}</div>
      <div class="label11">{{$parent.datas.result.importer.address}}</div>
      <div class="label12">{{$parent.datas.result.importer.zip_code}}</div>
      <div class="label13">{{$parent.datas.result.importer.city}}</div>
      <div class="label14">{{$parent.datas.result.importer.company}}</div>
      <div class="label15">{{$parent.datas.result.importer.mobile}}</div>
      <div class="label16" style="border: none">{{$parent.datas.result.importer.email}}</div>
    </div>
  </div> -->
  </div>
</template>

<script>
export default {
  name: 'obligationOrderAddress',
  beforeCreate() {

  },
  created() {

  },
}
</script>

<style lang="scss" scoped>
.obligationOrderAddressPage {
  background: #fff;
  padding: 16px 30px 30px;
  width: 1400px;
  margin: 30px auto 30px;
  border-radius: 6px;
  border: 1px solid #e2e2e2;

  .fontWeightBold {
    font-weight: bold;
  }

  .title {
    margin-bottom: 20px;
  }

  .label1 {
    width: 161px;
  }

  .label4 {
    width: 200px;
  }

  .label5 {
    width: 240px;
  }

  .label3 {
    width: 120px;
  }

  .label6 {
    width: 280px;
    padding: 0 20px;
  }

  .label7 {
    width: 120px;
  }

  .label8 {
    width: 160px;
  }

  .label9 {
    width: 173px;
  }

  //<div class="label10">{{$fanyi("收件人")}}</div>
  //<div class="label11">{{$fanyi("地址")}}</div>
  //<div class="label12">{{$fanyi("邮编")}}</div>
  //<div class="label13">{{$fanyi("城市")}}</div>
  //<div class="label14">{{$fanyi("公司")}}</div>
  //<div class="label15">{{$fanyi("电话")}}</div>
  //<div class="label16">{{$fanyi("电子邮箱")}}</div>
  .label11 {
    width: 280px;
    line-height: 15px !important;
  }

  .label12 {
    width: 132px;
  }

  .label14,
  .label15,
  .label16 {
    width: 200px;
  }

  .addressDelivery {
    border: 1px solid #e2e2e2;

    .addressDeliveryHeader,
    .importerGoodsHeader {
      background: #f9f9f9;
      height: 56px;

      div {
        font-size: 14px;
        text-align: center;
        line-height: 56px;
        color: #222222;
      }
    }

    .addressDeliveryFooter,
    .importerGoodsFooter {
      border-top: 1px solid #e2e2e2;
      height: 100px;

      div {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #e2e2e2;
        font-size: 14px;
        color: #222222;
      }
    }
  }
}
</style>
