<template>
  <el-dialog class="el-dialog-cus" v-bind="attributes" :visible.sync="$parent.oneGoodsPackingQuantity"
    :lock-scroll="false" append-to-body>
    <div class="main">
      <div class="dangQianXiangHao">
        {{ $fanyi('当前箱号') }}：{{ pagedata.number }}
      </div>
      <div>
        <el-table :data="pagedata.porder_freight_detail" :header-cell-style="{
          'text-align': 'center',
          'background-color': '#F6F6F6',
          color: '#000000',
          'font-weight': '400',
        }" :cell-style="{ 'text-align': 'center', color: '#000000' }">
          <el-table-column property="order_detail" :label="$fanyi('订单号')" width="240px">
            <template slot-scope="scope">
              <div>
                {{ scope.row.order_detail.order_sn }}
              </div>
            </template>
          </el-table-column>
          <el-table-column property="name" :label="$fanyi('番号')">
            <template slot-scope="scope">
              <div>
                {{ scope.row.order_detail.sorting }}
              </div>
            </template>
          </el-table-column>
          <el-table-column property="date" :label="$fanyi('图片')">
            <template slot-scope="scope">
              <div>
                <el-popover placement="right" trigger="hover">
                  <img :src="scope.row.order_detail.pic" alt="" style="width: 300px; height: 300px" />
                  <img class="image" @click="
                    $fun.toCommodityDetails({
                      goods_id: item.goods_id,
                      shop_type: item.shop_type,
                    })
                    " :src="scope.row.order_detail.pic" alt="" slot="reference" />
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column property="name" :label="$fanyi('商品属性')" width="200px">
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="hover">
                <div class="goodsDetailAll">
                  <div class="goodsDetailAllOneBox" v-for="(detailItem, detailIndex) in JSON.parse(
                    scope.row.order_detail.detail
                  )" :key="detailIndex" :title="detailItem.key + ':' + detailItem.value">
                    <h1 class="detailTitle">{{ detailItem.key }}</h1>
                    <p>
                      <span class="detailBody">{{ detailItem.value }}</span>
                    </p>
                  </div>
                </div>
                <div slot="reference" class="goodsDetail">
                  <div class="box">
                    <p v-for="(detailItem, detailIndex) in JSON.parse(
                      scope.row.order_detail.detail
                    )" :key="detailIndex" :title="detailItem.key + ':' + detailItem.value">
                      <span class="detailTitle">{{ detailItem.key }}</span> :
                      <span class="detailBody">{{ detailItem.value }}</span>
                    </p>
                  </div>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column property="num" :label="$fanyi('装箱数量')"></el-table-column>
        </el-table>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    config: Object,
  },
  data() {
    const {
      top = '20vh',
      width = '945px',
      title = this.$fanyi('装箱信息'),
      center = false,
    } = this.config || {}
    return {
      visible: true,
      attributes: {
        top,
        width,
        title,
        center,
        ...this.config,
      },
    }
  },
  methods: {},
  computed: {
    pagedata() {

      return this.$parent.oneData
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../../../../css/mixin.scss';

/deep/.el-table__header {
  border-radius: 6px !important;

}

.el-dialog-cus {
  /deep/.el-dialog {
    border-radius: 6px;
  }

  /deep/.el-dialog__title {
    line-height: 36px;
    font-size: 24px;
  }

  /deep/.el-dialog__header {
    border-bottom: solid 1px #ededed;
  }

  /deep/.el-dialog__headerbtn {}

  /deep/.el-dialog__body {
    padding: 20px 30px 40px;
  }

  /deep/.el-dialog__footer {
    .el-button {
      padding: 14px 38px;
      border-radius: 6px;

      font-size: 20px;

      margin: 0;

      &:first-child {
        color: white !important;
        margin: 0 !important;
      }

      &:last-child {
        margin-left: 80px;
        border: 1px solid $homePageSubjectColor;
        color: $homePageSubjectColor;
      }
    }
  }

  .diaText {
    font-size: 18px;
    color: #000000;
    line-height: 26px;
  }
}

// .el-table {
//   border: solid 1px #ededed;
// }
.main {
  .dangQianXiangHao {
    width: 242px;
    height: 40px;
    background: #e6f9f4;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
    color: #000000;
    font-size: 16px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #00c18b;
    line-height: 40px;

    margin-bottom: 20px;
  }
}

/deep/.el-image,
.image {
  $imgSize: 80px;
  width: $imgSize;
  height: $imgSize;
  transition: 0.3s;
  text-align: center;

  i {
    font-size: $imgSize/4;
    line-height: $imgSize;
  }
}

.goodsDetail {
  overflow: auto;
  height: 86px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    margin: 0 auto;
    display: inline-block;
    max-width: 100%;
    cursor: default;
    color: #000000;

    p {
      text-align: left;

      .detailTitle {}

      .detailBody {}
    }
  }
}

/deep/ .el-table__row>td {
  border: none;
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;

  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}
</style>
