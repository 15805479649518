<template>
  <div class="shouhuodizhi">
    <div class="shouhuodizhiblock" :style="!showLeft ? 'height:78px' : ''">
      <div class="shoublocktishi" @click="showLeft = !showLeft">
        <span>{{ $fanyi("收货地址") }}</span>
        <i class="el-icon-arrow-up" :class="{ roteteIcon: !showLeft }"></i>
      </div>
      <div class="chioseDiZhi">
        <!-- v-if="['临时保存'].indexOf(pageStatus) != -1" -->
        <el-select
          v-if="['临时保存'].indexOf(pageStatus) != -1"
          v-bind:class="{
            selectTextBox: ['临时保存'].indexOf(pageStatus) == -1,
          }"
          class="oselect"
          v-model="$parent.userActiveAddName.consignee"
          :placeholder="$fanyi('请选择预设收货地址')"
          @change="$parent.findValue($event, 'consignee')"
        >
          <el-option
            v-for="(item, index) in $parent.useraddLists.consignee"
            :key="item.id"
            :label="$fanyi('地址') + (index + 1)"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <dizhiFormVue :class="{ readOnly: leftOnlyRead }"></dizhiFormVue>
    </div>
    <div class="shouhuodizhiblock" :style="!showRight ? 'height:78px' : ''">
      <div class="shoublocktishi" @click="showRight = !showRight">
        <span>{{ $fanyi("进货商") }}</span>
        <i class="el-icon-arrow-up" :class="{ roteteIcon: !showRight }"></i>
      </div>
      <div class="chioseDiZhi">
        <!-- v-if="
            ['临时保存'].indexOf($parent.pageStatus) != -1 &&
            ['no', '个人通关'].indexOf($parent.form.personal_clearance) != -1
          " -->
        <el-select
          v-if="
            ['临时保存'].indexOf($parent.pageStatus) != -1 &&
            ['no', '个人通关'].indexOf($parent.form.personal_clearance) != -1
          "
          class="oselect"
          v-model="$parent.userActiveAddName.importer"
          :placeholder="$fanyi('请选择预设进口商')"
          @change="$parent.findValue($event, 'importer')"
          v-bind:class="{
            selectTextBox: ['临时保存'].indexOf($parent.pageStatus) == -1,
          }"
        >
          <el-option
            v-for="(item, index) in $parent.useraddLists.importer"
            :key="item.id"
            :label="$fanyi('地址') + (index + 1)"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <jinkoushangFormVue
        :class="{ readOnly: rightOnlyRead }"
      ></jinkoushangFormVue>
    </div>
  </div>
</template>
<script>
import dizhiFormVue from "../../../../../../payment/components/dizhiForm.vue";
import jinkoushangFormVue from "../../../../../../payment/components/jinkoushangForm.vue";
export default {
  data() {
    return {
      showLeft: true,
      showRight: true,
    };
  },
  components: { jinkoushangFormVue, dizhiFormVue },
  computed: {
    jinkoushang() {
      return this.$parent.userActiveAdd.importer;
    },
    shouhuodizhi() {
      return this.$parent.userActiveAdd.consignee;
    },
    leftOnlyRead() {
      if (["临时保存"].indexOf(this.$parent.pageStatus) == -1) {
        return true; //显示
      } else {
        return false; //隐藏
      }
    },
    rightOnlyRead() {
      if (
        ["临时保存"].indexOf(this.$parent.pageStatus) == -1 ||
        ["no", "个人通关"].indexOf(this.$parent.form.personal_clearance) == -1
      ) {
        return true; //显示
      } else {
        return false; //隐藏
      }
    },
  },
  created() {},
  methods: {},
};
</script>
<style lang='scss' scoped='scoped'>
@import "../../../../../../../css/mixin.scss";
.shouhuodizhi {
  width: $pageWidth;
  margin: 40px auto 0;
  display: flex;
  justify-content: space-between;
  //   align-items: flex-start;
  .shouhuodizhiblock {
    width: 680px;
    background-color: white;
    padding: 27px 30px 10px;
    overflow: hidden;
    transition: 0.3s;
    // height: 595px;
    .shoublocktishi {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      cursor: pointer;
      span {
        font-size: 18px;
        font-weight: 600;
        color: #222222;
        line-height: 26px;
      }
      .roteteIcon {
        transform: rotate(180deg);
      }
    }
    .chioseDiZhi {
      margin-bottom: 30px;
      /deep/.oselect {
        .el-input__inner {
          width: 620px;
          height: 64px;
          background: #ffffff;
          border: 1px solid #e2e2e2;
        }
      }
    }
    /deep/.firStepForm {
      width: 620px;
    }
    /deep/.firStepForm > ul > li {
      width: 300px;
    }
    /deep/input {
      font-size: 14px;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
    }
    /deep/.ipt {
      width: 300px;
    }
    /deep/.youbian {
      margin-right: 10px !important;
      .ipt {
        width: 100px;
      }
    }
    /deep/.chengshi {
      .ipt {
        width: 190px;
      }
    }
  }
  /deep/.readOnly {
    pointer-events: none;
  }
}
</style>