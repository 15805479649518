var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',_vm._b({staticClass:"el-dialog-cus",attrs:{"visible":_vm.$parent.oneGoodsPackingQuantity,"lock-scroll":false,"append-to-body":""},on:{"update:visible":function($event){return _vm.$set(_vm.$parent, "oneGoodsPackingQuantity", $event)}}},'el-dialog',_vm.attributes,false),[_c('div',{staticClass:"main"},[_c('div',{staticClass:"dangQianXiangHao"},[_vm._v(" "+_vm._s(_vm.$fanyi('当前箱号'))+"："+_vm._s(_vm.pagedata.number)+" ")]),_c('div',[_c('el-table',{attrs:{"data":_vm.pagedata.porder_freight_detail,"header-cell-style":{
        'text-align': 'center',
        'background-color': '#F6F6F6',
        color: '#000000',
        'font-weight': '400',
      },"cell-style":{ 'text-align': 'center', color: '#000000' }}},[_c('el-table-column',{attrs:{"property":"order_detail","label":_vm.$fanyi('订单号'),"width":"240px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.order_detail.order_sn)+" ")])]}}])}),_c('el-table-column',{attrs:{"property":"name","label":_vm.$fanyi('番号')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.order_detail.sorting)+" ")])]}}])}),_c('el-table-column',{attrs:{"property":"date","label":_vm.$fanyi('图片')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_c('el-popover',{attrs:{"placement":"right","trigger":"hover"}},[_c('img',{staticStyle:{"width":"300px","height":"300px"},attrs:{"src":scope.row.order_detail.pic,"alt":""}}),_c('img',{staticClass:"image",attrs:{"slot":"reference","src":scope.row.order_detail.pic,"alt":""},on:{"click":function($event){return _vm.$fun.toCommodityDetails({
                    goods_id: _vm.item.goods_id,
                    shop_type: _vm.item.shop_type,
                  })}},slot:"reference"})])],1)]}}])}),_c('el-table-column',{attrs:{"property":"name","label":_vm.$fanyi('商品属性'),"width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"bottom","trigger":"hover"}},[_c('div',{staticClass:"goodsDetailAll"},_vm._l((JSON.parse(
                  scope.row.order_detail.detail
                )),function(detailItem,detailIndex){return _c('div',{key:detailIndex,staticClass:"goodsDetailAllOneBox",attrs:{"title":detailItem.key + ':' + detailItem.value}},[_c('h1',{staticClass:"detailTitle"},[_vm._v(_vm._s(detailItem.key))]),_c('p',[_c('span',{staticClass:"detailBody"},[_vm._v(_vm._s(detailItem.value))])])])}),0),_c('div',{staticClass:"goodsDetail",attrs:{"slot":"reference"},slot:"reference"},[_c('div',{staticClass:"box"},_vm._l((JSON.parse(
                    scope.row.order_detail.detail
                  )),function(detailItem,detailIndex){return _c('p',{key:detailIndex,attrs:{"title":detailItem.key + ':' + detailItem.value}},[_c('span',{staticClass:"detailTitle"},[_vm._v(_vm._s(detailItem.key))]),_vm._v(" : "),_c('span',{staticClass:"detailBody"},[_vm._v(_vm._s(detailItem.value))])])}),0)])])]}}])}),_c('el-table-column',{attrs:{"property":"num","label":_vm.$fanyi('装箱数量')}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }