var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shouhuodizhi"},[_c('div',{staticClass:"shouhuodizhiblock",style:(!_vm.showLeft ? 'height:78px' : '')},[_c('div',{staticClass:"shoublocktishi",on:{"click":function($event){_vm.showLeft = !_vm.showLeft}}},[_c('span',[_vm._v(_vm._s(_vm.$fanyi("收货地址")))]),_c('i',{staticClass:"el-icon-arrow-up",class:{ roteteIcon: !_vm.showLeft }})]),_c('div',{staticClass:"chioseDiZhi"},[(['临时保存'].indexOf(_vm.pageStatus) != -1)?_c('el-select',{staticClass:"oselect",class:{
          selectTextBox: ['临时保存'].indexOf(_vm.pageStatus) == -1,
        },attrs:{"placeholder":_vm.$fanyi('请选择预设收货地址')},on:{"change":function($event){return _vm.$parent.findValue($event, 'consignee')}},model:{value:(_vm.$parent.userActiveAddName.consignee),callback:function ($$v) {_vm.$set(_vm.$parent.userActiveAddName, "consignee", $$v)},expression:"$parent.userActiveAddName.consignee"}},_vm._l((_vm.$parent.useraddLists.consignee),function(item,index){return _c('el-option',{key:item.id,attrs:{"label":_vm.$fanyi('地址') + (index + 1),"value":item.id}})}),1):_vm._e()],1),_c('dizhiFormVue',{class:{ readOnly: _vm.leftOnlyRead }})],1),_c('div',{staticClass:"shouhuodizhiblock",style:(!_vm.showRight ? 'height:78px' : '')},[_c('div',{staticClass:"shoublocktishi",on:{"click":function($event){_vm.showRight = !_vm.showRight}}},[_c('span',[_vm._v(_vm._s(_vm.$fanyi("进货商")))]),_c('i',{staticClass:"el-icon-arrow-up",class:{ roteteIcon: !_vm.showRight }})]),_c('div',{staticClass:"chioseDiZhi"},[(
          ['临时保存'].indexOf(_vm.$parent.pageStatus) != -1 &&
          ['no', '个人通关'].indexOf(_vm.$parent.form.personal_clearance) != -1
        )?_c('el-select',{staticClass:"oselect",class:{
          selectTextBox: ['临时保存'].indexOf(_vm.$parent.pageStatus) == -1,
        },attrs:{"placeholder":_vm.$fanyi('请选择预设进口商')},on:{"change":function($event){return _vm.$parent.findValue($event, 'importer')}},model:{value:(_vm.$parent.userActiveAddName.importer),callback:function ($$v) {_vm.$set(_vm.$parent.userActiveAddName, "importer", $$v)},expression:"$parent.userActiveAddName.importer"}},_vm._l((_vm.$parent.useraddLists.importer),function(item,index){return _c('el-option',{key:item.id,attrs:{"label":_vm.$fanyi('地址') + (index + 1),"value":item.id}})}),1):_vm._e()],1),_c('jinkoushangFormVue',{class:{ readOnly: _vm.rightOnlyRead }})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }