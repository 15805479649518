<template>
  <div class="main">
    <!-- 配送单详情提出配送单时弹出 -->
    <Dialog ref="dia3" :config="config">
      <div>
        <p>
          Estimado/a {{ $store.state.userInfo.user_name }}, actualmente se
          encuentra en la página de envío de solicitudes de entrega,
        </p>
        <p>
          Puede editar y ajustar la cantidad de envío y las opciones del
          producto en la página actual.Si tiene otros asuntos que necesitan
          nuestra atención, puede apuntarlo en 【Comentarios】 del albarán de
          entrega. Procesaremos de acuerdo con sus comentarios.
        </p>
        <p>
          La entrega del producto requiere información del importador y del
          destinatario, asegúrese de completar con precisión. (La información
          del importador de despacho de aduana será permisivamente la empresa
          española-RAKUMART) Después de confirmar que no hay problema, haga clic
          en el botón 【Enviar un albarán de entrega】 para enviar una orden de
          entrega.
        </p>
        <p>
          Después de recibir su solicitud de entrega y empacar la caja, le
          enviaremos los detalles de la tarifa.
        </p>
        <p>
          En ese momento, recibirá un correo electrónico de cotización con
          detalles específicos de la tarifa. Puede verificar la cotización
          específica y los detalles del embalaje en 【Mi cuenta】-【Continuar
          con el pago】.
        </p>
      </div>
    </Dialog>
    <!-- 配送单详情待付款时弹出 -->
    <Dialog ref="dia4" :config="config" :lock-scroll="false">
      <div>
        <p>
          Hello
          {{
            $store.state.userInfo.user_name
          }},{{ $fanyi('您即将查看配送单的详细内容，您可以在当前界面查看国际运费的报价信息。') }}
        </p>
        <p>
          {{
            $fanyi('确认无误后，您可以点击”去付款“进行支付。确认收款后，我们将安排物流发货。物流更进详情将通过邮件发给您。如果您需要调整产品包装等，请将详细信息发送给我们。根据您的修改要求进行调整后，我们将提供新的报价。')
          }}
        </p>
        <p>
          {{ $fanyi('（因为客户直接调整将导致产品的实际包装数据异常，我们限制了客户直接调整交付订单的功能，请谅解。）') }}
        </p>
      </div>

    </Dialog>
  </div>
</template>
<script>
import Dialog from '../../../../../../../components/public/Dialog.vue'

export default {
  data() {
    return {
      config: {
        top: '30vh',
        width: '800px',
        title: this.$fanyi('注意'),
        btnTxt: ['Have to Understood'],
      },
    }
  },
  components: { Dialog },
  computed: {
    status_name() {
      return this.$parent.datas.result.status_name
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.$refs.dia3.open('', () => { }, '')
    }
  },
  methods: {
    openOrderDia() {

      if (this.status_name == '等待付款') {
        this.$refs.dia4.open('', () => { }, '')
      }
      this.$forceUpdate()
    },
  },
}
</script>
<style lang="scss" scoped="scoped">
@import '../../../../../../../css/mixin.scss';

/deep/.el-dialog__footer .el-button:last-child {
  border: 0px !important;
}

.main {}

p {
  font-size: 16px;
  text-indent: 1cm;
  margin-bottom: 10px;
  line-height: 24px;
}
</style>
