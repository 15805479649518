<template>
  <div class="main" v-if="porder_freight">
    <danGeShangPingZhuangXiangXinXi></danGeShangPingZhuangXiangXinXi>
    <!-- {{ porder_detail }} -->
    <div class="huoyunTable">
      <div class="head tbc">
        <span>{{ $fanyi('发送方式') }}</span>
        <!--        -->

        <span>{{ $fanyi('物流单号') }}</span>
        <span>{{ $fanyi('计费重量') }}(kg)</span>
        <span>{{ $fanyi('美国派送时间') }}</span>
        <span>{{ $fanyi('费用($)') }}</span>
      </div>

      <div class="body">
        <div class="opt tbc" v-for="(item, i) in $parent.wuliu" :key="i">
          <span>{{ item.foreign_express ? item.foreign_express : '-------'
          }}({{ item.type == 0 ? $fanyi('国际') : $fanyi('国内') }})</span>
          <span>{{
            item.foreign_express_no ? item.foreign_express_no : '-------'
          }}</span>
          <span>{{ item.weight ? item.weight : '-------' }}</span>
          <span>{{ item.created_at ? item.created_at : '-------' }}</span>
          <span>{{ item.price ? item.price : '-------' }}</span>
        </div>
      </div>
    </div>

    <div class="goodsTable">
      <div class="head">
        <!-- 箱号 -->
        <span class="sn tdc">{{ $fanyi('箱号') }}</span>
        <!-- 实际重量 -->
        <span class="weight tdc">{{ $fanyi('实际重量') }}(kg)</span>
        <!-- 长 -->
        <span class="long tdc">{{ $fanyi('长') }}(cm)</span>
        <!-- 宽 -->
        <span class="wide tdc">{{ $fanyi('宽') }}(cm)</span>
        <!-- 高 -->
        <span class="high tdc">{{ $fanyi('高') }}(cm)</span>
        <!-- 体积 -->
        <span class="volume tdc">{{ $fanyi('体积') }}(m³)</span>
        <!-- 物流单号 -->
        <!-- <span class="trackingNumber tdc">{{ $fanyi("物流单号") }}</span> -->
      </div>
      <div class="body">
        <div class="bodyOpt" v-if="porder_freight.length == 0">
          <div class="optBody notHaveData">{{ $fanyi('暂无数据') }}</div>
        </div>
        <div class="bodyOpt" v-else>
          <div class="optBody" v-for="(orderItem, orderIndex) in porder_freight" :key="orderIndex"
            @click="openOne(orderItem)">
            <!-- 箱号 -->
            <span class="sn tdc con">
              <span class="box">
                {{ orderItem.number }}
              </span>
            </span>
            <!-- 实际重量 -->
            <span class="weight tdc">{{ orderItem.weight }}</span>
            <!-- 长 -->
            <span class="long tdc">{{ orderItem.length }}</span>
            <!-- 宽 -->
            <span class="wide tdc">{{ orderItem.width }}</span>
            <!-- 高 -->
            <span class="high tdc">{{ orderItem.height }} </span>
            <!-- 体积 -->
            <span class="volume tdc">{{
              $fun.ceil(
                (orderItem.length * orderItem.width * orderItem.height) /
                1000000,
                3
              )
            }}</span>
            <!-- 物流单号 -->
            <!-- <span class="trackingNumber tdc">{{
              $parent.datas.express_no
            }}</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fanyi from '../../../../../../../utlis/language'
import danGeShangPingZhuangXiangXinXi from './danGeShangPingZhuangXiangXinXi.vue'
export default {
  data() {
    return {
      oneGoodsPackingQuantity: false,
      oneData: {},
    }
  },
  components: {
    danGeShangPingZhuangXiangXinXi,
  },
  computed: {
    porder_freight() {
      if (
        this.$parent.datas.result &&
        this.$parent.datas.result.porder_freight
      ) {

        return this.$parent.datas.result.porder_freight
      }
    },
    porder_foreign_express() {
      if (
        this.$parent.datas.result &&
        this.$parent.datas.result.porder_foreign_express
      ) {
        return this.$parent.datas.result.porder_foreign_express
      }
    },
    huoYunData() {
      if (this.$parent.datas.result) {
        return {
          logistics: this.$parent.datas.result.logistics,
          express_no: this.$parent.datas.result.express_no,
          billing_parameter: this.$parent.datas.result.billing_parameter,
          amount: this.$parent.datas.result.amount,
        }
      }
    },
  },
  created() { },
  methods: {
    openOne(data) {
      this.oneGoodsPackingQuantity = true
      this.oneData = data
    },
  },
}
</script>
<style lang="scss" scoped="scoped">
@import '../../../../../../../css/mixin.scss';

.main {
  width: $pageWidth;
  margin: 0 auto 10px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  padding: 30px;
  font-size: 14px;
  border-radius: 6px;

  .huoyunTable {
    margin-bottom: 30px;

    .head {
      background: #bfd1ff;
      height: 56px;

      &.tbc {
        span {
          border: none !important;
        }
      }
    }

    .body {
      background-color: white;

      border-right: 1px solid #ededed;

      .opt {
        height: 60px;
      }
    }

    .tbc {
      display: flex;
      align-items: center;

      span {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        border-left: #ededed solid 1px;
        border-bottom: #ededed solid 1px;
      }
    }
  }

  .goodsTable {
    .head {
      height: 56px;
      background-color: rgba($color: #00c18b, $alpha: 0.4);
      display: flex;
      align-items: center;
    }

    .body {
      .bodyOpt {
        .optHead {
          height: 60px;
          display: flex;
          align-items: center;

          .title {
            font-size: 14px;
            color: #888888;
            line-height: 20px;
            margin-left: 20px;
          }

          .titlebody {
            font-size: 14px;
            color: #000000;
            line-height: 20px;
          }

          .downIcon {
            transition: 0.3s;
            line-height: 20px;
            transform: rotate(90deg);
            margin-left: 10px;

            &.open {
              transform: rotate(270deg);
            }
          }
        }

        .optBody {
          height: 60px;
          background: #fff;
          display: flex;
          align-items: center;
          border: solid 1px #ededed;
          border-bottom: none;
          margin-bottom: -1px;
          cursor: pointer;

          .box {
            border-radius: 4px;
          }

          &:hover {
            background-color: #fafafa;

            .box {
              display: block;
              width: 64px;
              height: 28px;
              background: #00c18b;
              color: white;
              border-radius: 4px;
              text-align: center;
              line-height: 28px;
            }
          }

          &.notHaveData {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;
          }

          .tdc {
            border-right: solid 1px #ededed;
            border-bottom: solid 1px #ededed;

            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }

    .tdc {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      &.sn {
        flex: 0 0 150px;

        &.con {
          .box {
            display: block;
            width: 64px;
            height: 28px;
            border: solid 1px #00c18b;
            color: #00c18b;
            text-align: center;
            line-height: 28px;
          }
        }
      }

      &.trackingNumber {
        flex: 0 0 313px;
      }
    }
  }
}
</style>
