<template>
  <div class="comblock" :style="!openBlock ? 'height:56px' : ''">
    <div class="blockTitle">
      <span>
        {{ $fanyi('国际费用明细') }}
      </span>
      <div class="imgBtn">
        <img class="jiahaoIcon" v-if="!openBlock" src="../../../../../../../assets/pulldown_1.svg"
          @click="openBlock = !openBlock" />
        <img class="jiahaoIcon" v-else src="../../../../../../../assets/pulldown_0.svg" @click="openBlock = !openBlock" />
      </div>
    </div>
    <div class="blockContent">
      <feiyongxiangqing></feiyongxiangqing>

      <div class="conLeft">
        <div>
          <div class="moudletitle">{{ $fanyi('通关方式') }}：</div>
          <div class="customsClearanceWay">

            <el-select size="small" class="tongguanfangshisel" v-model="$parent.form.personal_clearance" placeholder=""
              @change="$parent.changCustomsClearanceWay" v-bind:class="{
                selectTextBox: ['临时保存'].indexOf($parent.pageStatus) == -1,
              }">
              <el-option :label="$fanyi('双清包税')" value="yes"></el-option>
              <el-option :label="$fanyi('自行交税通关')" value="no"></el-option>
            </el-select>
          </div>
        </div>
        <div>
          <div class="moudletitle">{{ $fanyi('选择国际运输公司') }}：</div>

          <div class="customsClearanceWay">
            <el-select class="tongguanfangshisel" size="small" v-model="$parent.form.logistics" placeholder=""
              v-bind:class="{
                selectTextBox: ['临时保存'].indexOf($parent.pageStatus) == -1,
              }">
              <el-option v-for="item in $parent.wuLiuOptions" :key="item.name" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="conRight">
        <div class="moudletitle">{{ $fanyi('特殊要求备注') }}：</div>
        <!--        <div class="tishi">-->
        <!--          <span>-->
        <!--            *{{ $fanyi("对该订单有特殊要求的，可以在这边备注") }}<br />-->
        <!--            *{{ $fanyi("备注后会同步显示给业务员") }}-->
        <!--          </span>-->
        <!--        </div>-->

        <textarea v-model="$parent.form.client_remark" :readonly="['临时保存'].indexOf($parent.pageStatus) == -1"></textarea>
      </div>
    </div>
  </div>
</template>
<script>
import feiyongxiangqing from './feiyongxiangqing.vue'
export default {
  data() {
    return {
      openBlock: true,
    }
  },
  components: {
    feiyongxiangqing,
  },
  computed: {},
  created() { },
  methods: {},
}
</script>
<style lang="scss" scoped="scoped">
@import '../../../../../../../css/mixin.scss';

.comblock {
  margin: 0 auto 40px;
  width: 1400px;
  background-color: white;
  border: 1px solid #e2e2e2;
  height: 416px;
  background: #ffffff;
  padding: 0px 40px 0 40px;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  overflow: hidden;
  transition: all 0.5s;

  .blockTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    margin: 0 15px 0 8px;
    border-bottom: dashed 1px #e2e2e2;

    span {
      font-size: 16px;
      font-weight: bold;
      color: #222222;
      line-height: 24px;
    }

    .imgBtn {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .blockContent {
    display: flex;
    align-items: flex-start;

    // 标题
    .moudletitle {
      font-size: 16px;
      font-weight: 600;
      color: #222222;
      margin-bottom: 20px;
      line-height: 20px;
    }

    // 灰色小字提示
    .tishi {
      font-size: 14px;
      color: #999999;
      line-height: 20px;
      margin-bottom: 20px;
    }

    // 内容左
    .conLeft {
      width: 400px;
      margin-top: 30px;
      margin-left: 50px;

      /deep/.customsClearanceWay {
        margin-bottom: 40px;

        .tongguanfangshisel {
          .el-input__inner {
            width: 400px;
            height: 40px;
            background: #fafafa;
            border-radius: 6px;
            border: 1px solid #e2e2e2;
          }
        }
      }
    }

    // 内容右
    .conRight {
      margin-top: 30px;
      margin-left: 60px;

      textarea {
        width: 400px;
        height: 168px;
        background: #fafafa;
        border-radius: 6px;
        border: 1px solid #e2e2e2;
        padding: 10px;
        resize: none;
      }
    }
  }
}
</style>
