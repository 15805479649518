<template>
  <div class="operatingOptions">
    <div class="choies" v-if="['临时保存'].indexOf($parent.pageStatus) != -1">
      <input type="checkbox" :checked="$parent.checked" @click="
        $parent.checked = !$parent.checked
      $parent.allChoice()
        " />
      <button class="qx" @click="$parent.checked = !$parent.checked">
        {{ $fanyi('全选/取消') }}
      </button>
      <button @click="$parent.showAdd = !$parent.showAdd" style="margin-left: 15px; color: #ff730b">
        {{ $fanyi('添加') }}
      </button>
      <button class="delBtn" @click="$parent.delData">
        {{ $fanyi('删除') }}
      </button>
    </div>
    <!-- 配送单 -->
    <div class="shop">
      <p class="shopNums">
        <span>{{ $fanyi('商品种类') }}：{{
          $parent.orderData.shangPinZhongLei
        }}</span>
        <span>{{ $fanyi('数量总计') }} ：{{
          $parent.orderData.shuLiangZongJi
        }}</span>
      </p>
      <p class="prices">
        <!-- 占位空格 -->
        <!--        v-if="['等待付款'].indexOf($parent.pageStatus) != -1"-->
        <span class="huiLvBox" v-if="['临时保存', '报价中'].indexOf($parent.pageStatus) == -1">
          <div class="youhuijuan" v-if="['等待付款'].indexOf($parent.pageStatus) != -1">
            <el-checkbox v-model="couponChecked" @change="showCouponList">{{ $fanyi('使用优惠券') }}</el-checkbox>
            <span>{{ $parent.datas.result.coupon_num }}</span>
          </div>
          <span class="feiYongZongJi" v-if="['临时保存'].indexOf($parent.pageStatus) == -1">{{ $fanyi('费用总计') }}：</span>
          <font class="price">
            <span class="fuhao" v-if="['临时保存'].indexOf($parent.pageStatus) == -1">$</span>
            <span v-if="['临时保存'].indexOf($parent.pageStatus) == -1">
              <!--                              $fun.EURNumSegmentation($fun.ceil($parent.datas.amount))-->
              {{ $parent.datas.result.amount }}</span>
          </font>
        </span>
      </p>
    </div>
    <div class="buttonGroup" v-if="['临时保存'].indexOf($parent.pageStatus) != -1">
      <button @click="$parent.storageSendDeliver('tamp')">
        {{ $fanyi('临时保存') }}
      </button>
      <button @click="$fun.throttle($parent.storageSendDeliver('formal'), 3000)">
        {{ $fanyi('提出配送单') }}
      </button>
    </div>
    <div class="buttonGroup" v-if="['等待付款'].indexOf($parent.pageStatus) != -1">
      <button class="isPay" @click="$parent.goPay">
        {{ $fanyi('前往付款') }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      couponChecked: false,
    }
  },
  computed: {},
  created() { },
  methods: {
    //展示优惠券列表
    showCouponList(e) {

      if (e === true) {
        this.$emit('showSelectCouponDialogVisible', true)
      } else {
        this.$emit('showSelectCouponDialogVisible', false)
      }
    },
  },
}
</script>
<style lang="scss" scoped="scoped">
@import '../../../../../../../css/mixin.scss';

.operatingOptions {
  width: $pageWidth;
  height: 100px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto 0;
  display: flex;
  padding: 10px 0;
  justify-content: space-between;

  .huiLvBox {
    align-items: center !important;
    display: flex;
    height: 40px;

    .feiYongZongJi {
      position: relative;

      .qiTaFeiYong {
        width: 150px;
        text-align: left;
        position: absolute;
        left: 0;
        top: -35px;
      }
    }

    .huiLv {
      font-size: 14px;
      color: #888888;
      line-height: 19px;
    }

    .price {
      font-size: 18px;
      margin-left: 5px;
      font-family: Roboto-Black, Roboto;
      font-weight: 900;
      color: #ff730b;
      line-height: 33px;

      .fuhao {
        // font-size: 10px;
        display: inline-block;
        margin-right: 2px;
      }
    }

    .youhuijuan {
      position: relative;
      margin-right: 64px;

      span {
        position: absolute;
        top: 8px;
        right: 4px;
        font-size: 10px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        min-width: 24px;
        text-align: center;

        height: 18px;
        line-height: 18px;
        background: #ff730b;
        border-radius: 4px 4px 4px 0px;
      }
    }

    /deep/ .el-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;

      .el-checkbox__inner {
        border-radius: 50%;
        overflow: hidden;
        width: 20px;
        height: 20px;
        border: 1px solid #ff730b;
        margin-top: 20px;
      }

      input[type='checkbox'] {
        border: 1px solid #ff730b;
      }

      .el-checkbox__inner::after {
        left: 7px;
        top: 4px;
      }

      .el-checkbox__label {
        color: #ff730b;
      }
    }
  }

  .shiYongYouHuiQuan {
    .tiShi {
      font-size: 14px;
      line-height: 19px;
      color: #888888;
    }

    .userYouHuiQuan {
      margin-left: 10px;
      background-color: transparent;
    }
  }

  .choies {
    .delBtn {
      color: #1e2997;
    }

    .qx {
      margin-right: 10px;
    }

    // 加入商品库按钮
    .addLibraryOfGoods {
      height: 19px;
      font-size: 14px;

      color: #ff730b !important;
      line-height: 19px;
      letter-spacing: unset;
    }
  }

  >div {
    display: flex;
    align-items: center;

    &:first-child {
      color: #888888;
      font-size: 14px;

      input {
        margin-left: 33px;
        margin-right: 20px;
      }

      >button {
        font-size: 14px;

        color: #888888;
        line-height: 19px;

        background-color: transparent;

        &:first-child,
        &:nth-child(2) {
          color: #888888;
        }

        &:nth-child(4) {
          margin: 0 20px 0 40px;
        }

        &:nth-child(5) {
          color: #ffa724;
        }
      }
    }

    &.shop {
      margin-left: 40px;
      flex: 1;
      padding-right: 10px;

      >p {
        width: 50%;
        font-size: 14px;
        color: #000000;
        line-height: 19px;

        &.shopNums {
          display: flex;
          align-items: center;

          span {
            &:first-child {
              margin-right: 40px;
            }
          }
        }

        &.prices {
          text-align: right;
          display: flex;
          flex-direction: column;

          span {
            line-height: 20px;
            justify-content: flex-end;

            // margin
            .renMingBi {
              margin-left: 2px;
              margin-right: -3px;
            }

            .priceBox {
              margin-left: 5px;
              margin-right: 5px;
            }
          }
        }

        >span {
          line-height: 20px;
          margin: auto 0;
          display: block;
          display: flex;
          align-items: center;
        }

        .tiJi {
          margin-left: 40px;
        }
      }
    }

    &.buttonGroup {
      padding: 20px 0;

      >button {
        min-width: 180px;
        height: 56px;
        border: 1px solid #ff730b;
        font-size: 18px;
        font-weight: bold;
        color: #ff730b;
        background: white;
        line-height: 24px;
        padding: 0 10px;

        margin: 0 10px 0 30px;

        &.payBtn {
          color: #ffffff;
          background: #ff730b;
        }

        &.chongXinTiChu {
          color: #ffffff;
          background: #ff730b;
        }

        &.isPay {
          color: #ffffff;
          width: 200px;
          height: 60px;
          background: #ff730b;
          border-radius: 4px;
          background: #ff730b;
          margin-right: 30px;
        }

        &:nth-child(2) {
          color: #ffffff;
          background: #ff730b;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
