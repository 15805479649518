<template>
  <div class="DeliveryDetails">
    <HomePageTop />
    <Dialog ref="dialog" :config="config" />

    <el-dialog title="" width="1200px" :visible.sync="showAdd">
      <addlist v-if="showAdd" />
    </el-dialog>

    <div class="showdow">
      <!-- 表格 -->
      <div id="DeliveryDetailsTable">
        <div class="DeliveryDetailsTitle">
          {{ $fanyi('配送单详情') }}
        </div>
        <h1>
          <span>{{ $fanyi('配送单号') }}：{{ porder_sn }}</span>
          <span class="tishi" v-if="['待付款', '报价中', '等待付款', '美国国内配送中'].indexOf(
            pageStatus
          ) != -1
            ">
            {{ $fanyi('如果您想要修改配送单信息，请联系您的负责人员') }}
          </span>
        </h1>
        <div class="main">
          <div class="head">
            <div class="check-Box mainTd"></div>
            <div class="check-Box mainTd" v-if="['临时保存'].indexOf(pageStatus) != -1" @click="
              checked = !checked
            allChoice()
            $forceUpdate()
              ">
              <input type="checkbox" :checked="checked" @click.stop="
                checked = !checked
              allChoice()
                " />
            </div>
            <div class="choice mainTd">
              <!-- <div :title="$fanyi('选择')">{{ $fanyi("选择") }}</div> -->
            </div>
            <div class="goods mainTd">
              <div :title="$fanyi('产品')">{{ $fanyi('产品') }}</div>
            </div>
            <div class="detail mainTd">
              <div :title="$fanyi('属性')">{{ $fanyi('属性') }}</div>
            </div>
            <div class="price mainTd">
              <div :title="$fanyi('单价') + '($)'">{{ $fanyi('单价') }}($)</div>
            </div>
            <div class="goodsNum mainTd">
              <div :title="$fanyi('数量')">{{ $fanyi('数量') }}</div>
            </div>
            <div class="totalPrice mainTd">
              <div :title="$fanyi('总价') + '($)'">{{ $fanyi('总价') }}($)</div>
            </div>
            <div class="homeFreight mainTd">
              <div :title="$fanyi('国内运费') + '($)'">
                {{ $fanyi('国内运费') }}($)
              </div>
            </div>
            <!--            <div-->
            <!--              class="tariff mainTd"-->
            <!--              v-if="['临时保存', '报价中',''].indexOf(pageStatus) == -1"-->
            <!--            >-->
            <!--              <div :title="$fanyi('关税') + '($)'">-->
            <!--                {{ $fanyi("关税") }}($)-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="deliverableQuantity mainTd" v-if="['临时保存'].indexOf(pageStatus) != -1">
              <div :title="$fanyi('可发货数量')">
                {{ $fanyi('可发货数量') }}
              </div>
            </div>
            <div class="putForwardNumber mainTd">
              <div :title="$fanyi('提货数量')">{{ $fanyi('提货数量') }}</div>
            </div>
            <div class="remark mainTd">
              <div :title="$fanyi('备注')">{{ $fanyi('备注') }}</div>
            </div>
          </div>
          <div class="body">
            <div class="bodyOpt" v-for="(orderItem, orderIndex) in tableData" :key="orderIndex">
              <div class="optTitle">
                <div class="check-Box mainTd" v-if="['临时保存'].indexOf(pageStatus) != -1"></div>
                <div class="check-Box mainTd" v-if="['临时保存'].indexOf(pageStatus) != -1" @click="
                  orderItem.checked = !orderItem.checked
                shopAll(orderIndex)
                $forceUpdate()
                  ">
                  <input type="checkbox" :checked="orderItem.checked" @click.stop="
                    orderItem.checked = !orderItem.checked
                  shopAll(orderIndex)
                    " />
                </div>
                <div class="orderSn">
                  <img :class="orderItem.showGoodsList ? 'isShow' : ''" @click="
                    orderItem.showGoodsList = !orderItem.showGoodsList
                  $forceUpdate()
                    " :src="orderItem.showGoodsList
    ? require('../../../../../../assets/warehouse_1.svg')
    : require('../../../../../../assets/warehouse_0.svg')
    " alt="" />
                  <span>{{ $fanyi('订单号') }}：</span>
                  <button @click="
                    $fun.toPage(
                      '/orderDetails?type=' +
                      'peisong' +
                      '&order_sn=' +
                      orderItem.order_sn
                    )
                    ">
                    {{ orderItem.order_sn }}
                  </button>
                </div>
              </div>
              <div class="optBody" v-if="orderItem.showGoodsList">
                <div class="bodyRow" v-for="(goodsItem, goodsIndex) in orderItem.order_detail" :key="goodsIndex">
                  <!-- 选择-番号 -->
                  <div class="check-Box mainTd">
                    {{ goodsIndex + 1 }}
                  </div>
                  <!-- 选择-选项框 -->
                  <div class="check-Box mainTd" v-if="['临时保存'].indexOf(pageStatus) != -1" @click="
                    goodsItem.checked = !goodsItem.checked
                  oddChoice(goodsIndex)
                  $forceUpdate()
                    ">
                    <input type="checkbox" :checked="goodsItem.checked" @click.stop="
                      goodsItem.checked = !goodsItem.checked
                    oddChoice(goodsIndex)
                    $forceUpdate()
                      " />
                  </div>
                  <!-- 产品 -->
                  <div class="goods mainTd">
                    <el-popover placement="right" trigger="hover">
                      <img :src="goodsItem.pic" alt="" style="width: 300px; height: 300px" />

                      <el-image slot="reference" @click="
                        $fun.toCommodityDetails({
                          goods_id: goodsItem.goods_id,
                          shop_type: goodsItem.shop_type,
                        })
                        " :src="goodsItem.pic">
                        <div slot="error" :src="goodsItem.pic" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                        <div slot="placeholder">
                          <i class="el-icon-loading"></i>
                        </div>
                      </el-image>
                    </el-popover>

                    <!-- 商品标题 -->
                    <el-popover placement="bottom" width="400" trigger="hover">
                      <div class="goodsTitleAll">
                        {{ goodsItem.goods_title }}
                      </div>

                      <div class="goodsTitle" slot="reference" style="cursor: pointer" @click="
                        $fun.toCommodityDetails({
                          goods_id: goodsItem.goods_id,
                          shop_type: goodsItem.shop_type,
                        })
                        ">
                        {{ goodsItem.goods_title }}
                      </div>
                    </el-popover>
                  </div>
                  <!-- 属性 -->
                  <div class="detail mainTd">
                    <el-popover placement="bottom" trigger="hover">
                      <div class="goodsDetailAll">
                        <div class="goodsDetailAllOneBox" v-for="(detailItem, detailIndex) in goodsItem.detail"
                          :key="detailIndex" :title="detailItem.key + ':' + detailItem.value">
                          <h1 class="detailTitle">{{ detailItem.key }}</h1>
                          <p>
                            <span class="detailBody">{{
                              detailItem.value
                            }}</span>
                          </p>
                        </div>
                      </div>
                      <div class="detailOptBox" slot="reference">
                        <p class="detailOpt" v-for="(detailItem, detailIndex) in goodsItem.detail" :key="detailIndex"
                          :title="detailItem.key + ':' + detailItem.value">
                          {{ detailItem.key }}
                          :{{ detailItem.value }}

                        </p>
                      </div>
                    </el-popover>
                  </div>
                  <!-- 单价(￥) -->
                  <div class="price mainTd">
                    {{ $fun.RMBNumSegmentation($fun.ceil(goodsItem.confirm_price * orderItem.order.exchange_rate)) }}
                  </div>
                  <!-- 数量 -->
                  <div class="goodsNum mainTd">{{ goodsItem.confirm_num }}</div>
                  <!-- 总价(￥) -->
                  <div class="totalPrice mainTd">
                    {{ $fun.RMBNumSegmentation($fun.ceil(goodsItem.totalPrice * orderItem.order.exchange_rate)) }}
                  </div>
                  <!-- 国内运费 -->
                  <div class="homeFreight mainTd">
                    {{ $fun.RMBNumSegmentation($fun.ceil(goodsItem.confirm_freight * orderItem.order.exchange_rate)) }}
                  </div>
                  <!-- 关税($) -->
                  <!--                  <div-->
                  <!--                    class="tariff mainTd"-->
                  <!--                    v-if="['临时保存', '报价中'].indexOf(pageStatus) == -1"-->
                  <!--                  >-->
                  <!--                    0-->
                  <!--                  </div>-->
                  <!-- 在库数量 -->
                  <div class="deliverableQuantity mainTd" v-if="['临时保存'].indexOf(pageStatus) != -1">
                    {{ goodsItem.totalWaitSubmitNum }}
                  </div>
                  <!-- 提出数 -->
                  <div class="putForwardNumber mainTd">
                    <el-input v-model="goodsItem.submitNum" :readonly="['临时保存'].indexOf(pageStatus) == -1"
                      oninput="this.value=Number(this.value.replace(/[^\d]/g,''))" @input="setPutNum(goodsItem)"
                      @change="setOrderNum"></el-input>
                  </div>
                  <!-- 备注 -->
                  <div class="remark mainTd">
                    <el-input class="remarkBox" :readonly="['临时保存'].indexOf(pageStatus) == -1"
                      v-model="goodsItem.client_remark" type="textarea" :placeholder="['临时保存'].indexOf(pageStatus) != -1
                        ? $fanyi('请输入备注')
                        : ''
                        ">
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <caozuolanVue ref="caozuolan" @showSelectCouponDialogVisible="showSelectCouponDialogVisible"></caozuolanVue>
    </div>

    <!-- 通关方式 -->
    <tongGuanFangShiVue></tongGuanFangShiVue>

    <el-dialog :visible.sync="couponChecked" width="480px" :show-close="false" @close="closeDlong">
      <coupons ref="Deliveryorder" @cloneDialogVisible="cloneDialogVisible" :status="datas.status" porder_sn=" porder_sn"
        @getActiveCoupon="getActiveCoupon" :key="timer" />
    </el-dialog>
    <dgoodsInfo v-if="['临时保存', '报价中'].indexOf(pageStatus) == -1" />
    <customerPrompt ref="customerPrompt" style="background: transparent; box-shadow: none; height: 0px"></customerPrompt>
    <obligationOrderAddress />
  </div>
</template>
<script>
import HomePageTop from '../../../../../../components/head/HomePageTop.vue'
import addlist from '../../../warehouse/index.vue'
import dgoodsInfo from './components/dgoodsInfo.vue'
import Dialog from '../../../../../../components/public/Dialog.vue'

import customerPrompt from './components/customerPrompt'
import tongGuanFangShiVue from './components/tongGuanFangShi.vue'
import shouhuodizhiVue from './components/shouhuodizhi.vue'
import caozuolanVue from './components/caozuolan.vue'

import obligationOrderAddress from './components/obligationOrderAddress'
import coupons from '../../../userOrder/views/components/Coupons'
export default {
  name: '',
  data() {
    return {
      datas: { result: {} }, //所有数据
      tableData: [], //上面表格数据
      orderData: null, //配送单详情页面下面表格数据
      checked: false, //全选
      pageStatus: '待发货', //页面状态，参数：临时保存、报价中、等待付款、待发货
      timer: '',
      couponChecked: false,
      // 接口请求条件
      form: {
        ids: this.$route.query.id,
        personal_clearance: 'yes',
        logistics: '',
        client_remark: '',
      },
      wuliu: [],
      // 订单其他信息
      orderData: {
        shangPinZhongLei: 0, //商品种类
        shuLiangZongJi: 0, //数量总计
        zongJia: 0, //总价(人民币)
      },
      // 配送单号
      porder_sn: '',

      //选择的地址名字
      userActiveAddName: {
        importer: '',
        consignee: '',
      },
      //选择的地址详细信息
      userActiveAdd: {
        importer: {
          user_id: 1,
          type: 1,
          company: '',
          zip_code: '',
          address: '',
          contacts: '',
          mobile: '',
          tel: '',
          mark: '',
          email: '',
          cnpj: '',
        },
        consignee: {
          user_id: 1,
          type: 2,
          company: '',
          zip_code: '',
          address: '',
          contacts: '',
          mobile: '',
          tel: '',
          mark: '',
          email: '',
          cnpj: '',
        },
      },
      //用户地址列表
      useraddLists: {},
      wuLiuOptions: [], //物流方式列表
      showAdd: false, //是否显示添加商品弹窗
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('提示'),
        btnTxt: [this.$fanyi('删除'), this.$fanyi('取消')],
      },
    }
  },
  components: {
    HomePageTop,
    dgoodsInfo,
    addlist,

    Dialog,
    customerPrompt,
    tongGuanFangShiVue,
    shouhuodizhiVue,
    caozuolanVue,

    obligationOrderAddress,
    coupons,
  },
  computed: {},
  created() {
    // 获取数据
    this.getData()
    // 获取用户地址
    this.useraddressList()
    // // 获取物流方式列表

  },
  methods: {
    // 更改通关方式
    changCustomsClearanceWay() {
      if (this.form.personal_clearance == 'yes') {

        this.userActiveAdd.importer = this.datas.importer_address
      } else {
        this.userActiveAdd.importer = {
          address: '',
          cnpj: '',
          company: '',
          contacts: '',
          email: '',
          id: 2,
          mark: '',
          mobile: '',
          tel: '',
          type: 1,
          user_Id: 0,
          coupon_ids: '',
          zip_code: '',
        }
        if (
          this.useraddLists.importer &&
          ['临时保存'].indexOf(this.pageStatus) != -1
        ) {
          this.useraddLists.importer.forEach((addListItem) => {
            if (addListItem.acquiescent) {
              this.userActiveAddName.importer = addListItem
              this.findValue(addListItem.id, 'importer')
            }
          })
        }
      }
    },
    // 获取数据
    getData() {
      if (this.$route.query.order_sn) {
        this.$api
          .storageDeliverDetail({ porder_sn: this.$route.query.order_sn })
          .then((res) => {
            if (res.code != 0) return

            this.porder_sn = res.data.porder_sn

            this.datas = res.data

            this.wuliu = res.data.result.porder_foreign_express


            this.pageStatus = res.data.result.status_name

            // 备注
            this.form.client_remark = res.data.result.client_remark
            // 地址
            if (res.data.result.importer) {
              this.userActiveAdd.importer = res.data.result.importer
            }
            if (res.data.result.receive) {
              this.userActiveAdd.consignee = res.data.result.receive
            }

            // 通关方式
            if (res.data.result.personal_clearance_name == 'rakumart代通关') {
              this.form.personal_clearance = 'yes'
            } else if (res.data.result.personal_clearance_name == '个人通关') {
              this.form.personal_clearance = 'no'
            }

            this.tableData = this.datas.result.porder_detail

            // 调用弹出提示函数
            this.$refs.customerPrompt.openOrderDia()
            this.detailDataTreating()
            this.$api.internationalLogisticsTransportation().then((res) => {
              if (res.code != 0) return
              if (res.data.length == 0) return false
              res.data.forEach((item) => {
                if (
                  item.useful_rule.logistics_id === this.datas.result.logistics
                ) {
                  this.form.logistics = item.name
                }
              })
              this.wuLiuOptions = res.data
            })
          })
      } else if (this.$route.query.id) {
        this.pageStatus = '临时保存'
        this.$api
          .storageSendDeliverPreview({ ids: this.form.ids })
          .then((res) => {
            if (res.code != 0) return
            this.datas = res.data

            this.porder_sn = res.data.porder_sn
            this.tableData = res.data.result
            // 进口商地址默认赋值
            this.userActiveAdd.importer = this.datas.importer_address
            // 调用弹出提示函数
            this.$refs.customerPrompt.openOrderDia()
            this.dataTreating()
            this.$api.internationalLogisticsTransportation().then((res) => {
              if (res.code != 0) return
              if (res.data.length == 0) return false
              res.data.forEach((item) => {
                if (
                  item.useful_rule.logistics_id === this.datas.result.logistics
                ) {
                  this.form.logistics = item.name
                }
              })
              this.wuLiuOptions = res.data
            })
          })
      }
    },
    // 计算商品种类和数量总计
    setOrderNum() {
      this.orderData = {
        shangPinZhongLei: 0, //商品种类
        shuLiangZongJi: 0, //数量总计
        zongJia: 0, //总价(人民币)
      }
      this.tableData.forEach((orderItem) => {
        orderItem.order_detail.forEach((goodsItem) => {
          this.orderData.shangPinZhongLei++
          this.orderData.shuLiangZongJi += Number(goodsItem.submitNum)
        })
      })

    },
    //关闭弹窗
    cloneDialogVisible(msg) {

      this.couponChecked = msg
      this.$refs.caozuolan.couponChecked = msg
    },
    // 关闭时触发的时间
    closeDlong() {
      if (!this.coupon_ids) {
        this.$refs.caozuolan.couponChecked = false
      }

    },
    //将选择的优惠券后的价格重新计算
    async getActiveCoupon(msg, flag) {
      this.coupon_ids = msg

      const res = await this.$api.storagePOrderPayment({
        porder_sn: this.porder_sn,
        coupon_ids: msg,
      })
      if (res.code == 10000) {
        this.$message.error(this.$fanyi(res.msg))
        return
      }

      this.couponChecked = false
      this.$refs.caozuolan.couponChecked = flag
      //  Vue.set(this.datas.result,'amount',res.data.porder_amount)
      this.datas.result.amount = res.data.porder_amount
    },
    // 配送单详情处理数据
    detailDataTreating() {

      this.orderData = {
        shangPinZhongLei: 0, //商品种类
        shuLiangZongJi: 0, //数量总计
        zongJia: 0, //总价(人民币)
      }
      this.tableData.forEach((orderItem) => {
        orderItem.showGoodsList = true
        orderItem.checked = false
        // orderItem.order_detail = [orderItem.order_detail];
        orderItem.order_detail.forEach((goodsItem) => {
          this.orderData.shangPinZhongLei++
          this.orderData.shuLiangZongJi += Number(goodsItem.submitNum)
          goodsItem.totalPrice = this.$fun.ceil(
            goodsItem.confirm_num * goodsItem.confirm_price
          )
          goodsItem.checked = false
        })
      })

      //费用总计= 其他费用+国际物流费用+美国物流费用+增值税+反倾销税，因为购买商品的费用在之前已经支付过了
      this.orderData.zongJia =
        Number(this.datas.result.other_price) +
        Number(this.datas.result.logistics_price) +
        Number(this.datas.result.foreign_express_price) +
        Number(this.datas.result.added_price) +
        Number(this.datas.result.clearance_price)
    },
    // 配送单提出预览处理数据
    dataTreating() {
      this.orderData = {
        shangPinZhongLei: 0, //商品种类
        shuLiangZongJi: 0, //数量总计
        zongJia: 0, //总价(人民币)
      }
      this.tableData.forEach((orderItem) => {
        orderItem.showGoodsList = true
        orderItem.checked = false
        orderItem.order_detail.forEach((goodsItem) => {
          this.orderData.shangPinZhongLei++
          this.orderData.shuLiangZongJi += Number(goodsItem.submitNum)
          this.orderData.zongJia += this.$fun.ceil(
            goodsItem.confirm_num * goodsItem.confirm_price
          )
          goodsItem.totalPrice = Number(
            this.$fun.ceil(goodsItem.confirm_num * goodsItem.confirm_price)
          )
          goodsItem.checked = false
        })
      })
    },
    // 单选
    oddChoice(i) {
      let flag = this.tableData[i].order_detail.every(
        (item) => item.checked == true
      )
      if (flag) {
        this.tableData[i].checked = true
      } else {
        this.tableData[i].checked = false
      }
      this.isCheckedAll()
      this.$forceUpdate()
      //   flag ? (this.tableData[i].checked = true) : (this.tableData[i].checked = false);
    },
    // 店铺全选
    shopAll(i) {
      if (this.tableData[i].checked) {
        this.tableData[i].order_detail.forEach((item) => {
          item.checked = true
        })
      } else {
        this.tableData[i].order_detail.forEach((item) => {
          item.checked = false
        })
      }
      this.$forceUpdate()
      this.isCheckedAll()
    },
    // 全选
    allChoice() {
      if (this.checked) {
        this.tableData.forEach((item, index) => {
          item.checked = true
          this.shopAll(index)
        })
      } else {
        this.tableData.forEach((item, index) => {
          item.checked = false
          this.shopAll(index)
        })
      }
      this.$forceUpdate()
    },
    // 是否全选中
    isCheckedAll() {
      var flag = this.tableData.every((item, index) => item.checked == true)
      if (flag == true) {
        this.checked = true
      } else {
        this.checked = false
      }
    },
    //展示选择优惠券弹窗
    showSelectCouponDialogVisible(e) {

      if (e == true) {
        this.couponChecked = true
      } else {

        this.getActiveCoupon('', e)
      }
    },
    // 临时保存或提交订单并跳转到配送单列表页面
    storageSendDeliver(type) {
      if (type == 'formal') {
        // 收货地址
        for (let i in this.userActiveAdd.consignee) {

          if (
            [
              'type',
              'company',
              'zip_code',
              'address',
              'contacts',
              'mobile',
              'tel',
              'email',
              'cnpj',
            ].indexOf(i) != -1 &&
            !this.userActiveAdd.consignee[i]
          ) {
            this.$fun.maoDian(this.$refs.myreffeiyongeee)
            return this.$message(this.$fanyi('提出配送单请将地址填写完整'))
          }
        }
        let url = this.$route

        // 进口商
        for (let i in this.userActiveAdd.importer) {
          if (
            [
              'type',
              'company',
              'zip_code',
              'address',
              'contacts',
              'mobile',
              'tel',
              'email',
              'cnpj',
            ].indexOf(i) != -1 &&
            !this.userActiveAdd.importer[i]
          ) {
            this.$fun.maoDian(this.$refs.myreffeiyongeee)
            return this.$message(this.$fanyi('提出配送单请将地址填写完整'))
          }
        }
      }
      let ids = []
      this.tableData.forEach((orderItem) => {
        delete orderItem.showGoodsList
        delete orderItem.checked

        orderItem.order_detail.forEach((goodsItem) => {
          delete goodsItem.totalPrice
          delete goodsItem.checked
          ids.push(goodsItem.id)
        })
      })
      if (ids.length == 0) {
        return this.$message(this.$fanyi('订单里请至少添加一款商品'))
      }
      let datas = {
        submitStatus: type,
        porder_sn: this.porder_sn,
        ids: ids.join(','),
        data: JSON.stringify(this.tableData),
        logistics: this.form.logistics,
        personal_clearance:
          this.form.personal_clearance == 'yes'
            ? 'no'
            : this.form.personal_clearance == 'no'
              ? 'yes'
              : this.form.personal_clearance,
        client_remark: this.form.client_remark,
      }
      // 假如是个人通关则传递地址
      // if (this.form.personal_clearance == "no") {
      datas.consignee_address = JSON.stringify(this.userActiveAdd.consignee)
      datas.importer_address = JSON.stringify(this.userActiveAdd.importer)
      // }

      if (
        this.form.personal_clearance == 'no' &&
        datas.consignee_address == {} &&
        datas.importer_address == {}
      ) {
        return this.$message(this.$fanyi('通关方式为个人通关，请填写地址信息'))
      }
      this.$api.storageSendDeliver(datas).then((res) => {

        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
        // 如果是临时保存，跳转到配送单列表临时保存的状态
        if (type != 'formal') {
          this.$fun.toPage('/user/deliveryList?status=temporary')
        } else {
          this.$fun.toPage('/user/deliveryList')
        }
      })
    },
    // 将指定id的对象填充到地址框
    findValue(e, type) {

      let items = this.useraddLists[type].find((item) => item.id == e)
      this.userActiveAdd[type] = items

    },
    // 获取用户所有地址
    useraddressList() {
      this.$api.useraddressList().then((res) => {
        if (res.code != 0) return false
        this.useraddLists = res.data

        // 检查用户是否有修改过收货地址
        let notNull = false
        for (let consiitem in this.userActiveAdd.consignee) {
          if (
            ['id', 'mark', 'type', 'user_Id'].indexOf(
              this.userActiveAdd.consignee[consiitem]
            ) == -1 &&
            this.userActiveAdd.consignee[consiitem]
          ) {
            notNull = true
          }
        }

        // 如果收货列表存在并且配送单状态是临时保存并且用户未填写地址时匹配默认地址,另外一个页面打开的时候不需要匹配，因为默认是代通关，如果个人通关用户一定填了地址
        if (
          this.useraddLists.consignee &&
          ['临时保存'].indexOf(this.pageStatus) != -1 &&
          notNull == true
        ) {
          this.useraddLists.consignee.forEach((addListItem) => {
            if (addListItem.acquiescent) {
              this.userActiveAddName.consignee = addListItem
              this.findValue(addListItem.id, 'consignee')
            }
          })
        }
      })
    },
    // 限制提出数量不能大于可提出数
    setPutNum(item) {
      if (item.totalWaitSubmitNum < item.submitNum) {
        item.submitNum = item.totalWaitSubmitNum
        this.$message(this.$fanyi('提出数不能超过可提出数'))
      }
    },
    // 删除商品
    delData() {
      let iddff = false
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].checked == true) {
          iddff = true
          break
        }
        for (let j = 0; j < this.tableData[i].order_detail.length; j++) {
          if (this.tableData[i].order_detail[j].checked == true) {
            iddff = true
            break
          }
        }
      }

      if (iddff == false) {
        return this.$message.warning(this.$fanyi('未选择操作的商品'))
      }
      this.$refs.dialog.open(
        this.$fanyi('是否确认删除？'),
        () => {
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].checked == true) {
              this.tableData.splice(i, 1)
              i--
              continue
            }

            for (let j = 0; j < this.tableData[i].order_detail.length; j++) {
              if (this.tableData[i].order_detail[j].checked == true) {
                this.tableData[i].order_detail.splice(j, 1)
                j--
              }
            }
          }
          this.dataTreating()
        },
        () => { }
      )
    },
    // 前往付款页面
    goPay() {
      this.$fun.toPage(
        '/payment?porder_sn=' +
        this.porder_sn +
        '&peiSong=true' +
        '&coupon_ids=' +
        this.coupon_ids
      )
    },
    // 添加数据
    addGoods(goodsData) {

      goodsData.forEach((orderItem) => {

        if (
          this.tableData.filter((item) => item.order_sn == orderItem.order_sn)
            .length != 0
        ) {
          let index = this.tableData.indexOf(
            this.tableData.find((item) => item.order_sn == orderItem.order_sn)
          )

          orderItem.order_detail.forEach((goodsItem) => {
            this.tableData[index].order_detail.push(goodsItem)
          })
        } else {
          this.tableData.push(orderItem)
        }
      })
      this.dataTreating()
      this.showAdd = false
    },
  },
}
</script>
<style lang="scss" scoped="scoped">
@import '../../../../../../css/mixin.scss';

.DeliveryDetails {
  padding-bottom: 156px;
  background: #f9f9f9;
}

/deep/ .el-dialog {
  border-radius: 6px;
}

/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  // overflow: auto;
  padding: 0;
}

/deep/ .el-dialog__footer {
  padding: 0 30px 30px;
}

.showdow {
  width: $pageWidth;
  margin: 0 auto;
}

#DeliveryDetailsTable {
  width: $pageWidth;
  background: #ffffff;
  border-bottom: none;

  border-radius: 6px;
  border: 1px solid #e2e2e2;
  overflow: hidden;
  margin: 10px auto 0;
  padding: 20px 20px 40px 20px;

  .DeliveryDetailsTitle {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #222222;
    line-height: 28px;
    letter-spacing: 1px;
  }

  h1 {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #222222;
    letter-spacing: 1px;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    .tishi {
      min-width: 300px;
      height: 32px;
      background: #eff4ff;
      border-radius: 4px;
      padding: 0 10px;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      text-align: center;
      color: #608bff;
      line-height: 32px;
    }
  }

  .main {
    width: 100%;

    // border: 1px solid #e2e2e2;
    .head {
      width: 100%;
      height: 60px;
      background: #f6f6f6;
      border: 1px solid #e2e2e2;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #222222;
      position: sticky;
      z-index: 9;

      border: 1px solid #e2e2e2;
      top: 67px;

      .mainTd {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border: none;
      }
    }

    .body {
      .bodyOpt {
        // margin-bottom: 20px;

        .optTitle {
          display: flex;
          align-items: center;
          height: 60px;
          background: #ffffff;
          border: 1px solid #e2e2e2;
          border-top: 0;

          .orderSn {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 20px;
            padding-left: 10px;

            button {
              font-size: 14px;
              color: $homePageSubjectColor;
            }
          }

          img {
            margin-left: 12px;
            width: 16px;
            height: 16px;
            margin-right: 10px;
            // transform: rotate(90deg);
            transition: 0.3s;
            cursor: pointer;

            &.isShow {
              transform: rotate(360deg);
            }
          }
        }

        .optBody {
          border-top: none;

          .bodyRow {
            height: 120px;
            display: flex;
            align-items: center;
            background: #f6f6f6;
            border: 1px solid #e2e2e2;
            font-size: 14px;
            border-top: 0;

            .goods {
              flex: 0 0 320px;

              // width: 320px;
              /deep/.el-image {
                $imgSize: 60px;
                width: $imgSize;
                height: $imgSize;
                transition: 0.3s;
                text-align: center;
                margin-right: 10px;
                cursor: pointer;

                i {
                  font-size: $imgSize/4;
                  line-height: $imgSize;
                }
              }

              .goodsTitle {
                width: 100%;
                height: 80px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
              }
            }

            .detail {
              display: flex;
              flex-direction: column;
              justify-content: center;
              text-overflow: -o-ellipsis-lastline;
              padding: 10px 0;
              overflow: hidden;
            }

            .putForwardNumber {
              padding: 0px;

              /deep/.el-input__inner {
                height: 32px;
                text-align: center;
              }
            }

            .remark {
              display: flex;
              justify-content: center;
              align-items: center;

              .remarkBox {
                width: 140px;

                background: #ffffff;
                border-radius: 2px;
                height: 80px;

                /deep/.el-textarea__inner {
                  height: 80px;
                  font-size: 12px;
                  line-height: 18px;
                }
              }
            }
          }
        }
      }
    }

    .mainTd {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.check-Box {
        flex: 0 0 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.choice {
        flex: 0 0 70px;
      }

      &.goods {
        flex: 0 0 254px;
        justify-content: flex-start;
        padding: 0 10px;
      }

      &.detail {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 140px;
        height: 100%;

        p {
          width: 120px;
        }
      }



      &.homeFreight {
        flex: 0 0 100px;
      }

      &.tariff {
        flex: 0 0 100px;
      }


      &.putForwardNumber {
        flex: 0 0 120px;
      }

      &.remark {
        flex: 0 0 160px;
        border-right: none;
      }
    }
  }
}

.theFullCost {
  display: flex;
  width: $pageWidth;
  background: rgba($color: #3a4de4, $alpha: 0.05);
  margin: 0 auto 0px;
  border-radius: 0px;

  .fujiafuwu {
    position: relative;

    .priceDetail {
      position: absolute;
      top: 10px;
      left: 100%;
      margin-left: 10px;
      width: 242px;
      min-height: 74px;
      background-color: white;
      z-index: 2;

      >ul {
        display: flex;
        border-radius: 10px;

        >li {
          height: 37px;
          background: #fafafa;
          flex: 1;
          height: 37px;
          line-height: 37px;
          border: solid 1px #ededed;
          margin: 0 -1px -1px 0;
          font-size: 12px;
          color: #000000;
        }

        &.obody {
          li {
            background-color: white;

            &:first-child {
              background: #fafafa;
            }
          }
        }
      }
    }
  }

  >div {
    padding: 20px;

    >h2 {
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    &.Con {
      width: 270px;
      font-size: 12px;

      color: #000000;
      line-height: 16px;

      /deep/.tgfs {
        margin: 3px 0 0;
        width: 210px;
      }

      .customsClearanceWay {
        display: flex;
        align-items: center;

        /deep/.el-select {
          width: 210px;
        }

        .answer {
          margin-left: 10px;
        }
      }
    }

    &.yunShuGongSi {
      margin-right: 30px;

      .ysm {
        margin-bottom: 10px;
      }

      >span {
        display: block;
        width: 180px;
        font-size: 12px;

        color: #888888;
        line-height: 16px;
      }
    }

    &.teShuBeiZhu {
      flex: 1;

      >h2 {
        margin-top: 10px;
        line-height: unset;
      }

      >textarea {
        width: 100%;
        height: 120px;
        border-radius: 4px;
        border: 1px solid #e4e7ed;
        margin-bottom: 5px;
        padding-left: 10px;
        padding-top: 7px;
        background-color: white;
      }

      >span {
        font-size: 12px;

        color: #888888;
        line-height: 16px;
      }
    }
  }
}

.addressAdministration {
  margin: 0 auto;
  width: $pageWidth;
  background: rgba($color: #3a4de4, $alpha: 0.05);
  margin-bottom: 20px;
  display: flex;

  padding: 30px;

  .optOne {
    &:first-child {
      margin-right: 236px;
    }

    .modelTitle {
      font-weight: 600;
      white-space: nowrap;
    }

    >p {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &.oheader {
        margin-bottom: 20px;
        min-height: 30px;

        span {
          display: flex;
          justify-content: flex-start !important;
          margin-right: auto;
        }

        .oselect {
          /deep/.el-input {
            width: 180px;
            height: 30px;
            // border: 1px solid rgba($color: $homePageSubjectColor, $alpha: 0.3);
          }

          /deep/.el-input__inner {
            font-size: 12px;

            color: #000000;
            line-height: 16px;
            width: 120px;
            height: 34px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid rgba(96, 139, 255, 0.3);

            height: 34px;
          }

          /deep/.el-select__caret {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      >span {
        display: flex;
        align-items: center;

        &:first-child {
          width: 145px;
          justify-content: flex-end;
          margin-right: 10px;
          font-size: 12px;
        }
      }

      >input,
      .dizhiShurukuang {
        width: 240px;
        height: 22px;
        background: #fff;
        border-radius: 3px;
        border: 1px solid #d7d7d7;
        padding-left: 10px;

        &::placeholder {
          color: #ccc;
        }
      }

      >.dizhiShurukuang {
        height: 80px;
      }
    }
  }
}

.feiYongDizhi {
  width: 1340px;
  margin: 0 auto;
  height: 1px;
  border: none;
  background-color: #dbdbdb;
  margin-bottom: -1px;
}

.detailOptBox {
  width: 120px;
  height: 80px;


  overflow-y: auto !important;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #c0c0c0;
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .detailOpt {
    max-width: 116px;

    text-overflow: -o-ellipsis-lastline;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow-y: auto;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    span {
      display: inline-block;
      width: 120px;
    }
  }
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;

  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}

.goodsTitleAll {
  font-size: 18px;
}

/deep/.is-disabled .el-input__inner {
  color: black !important;
}

/deep/.selectTextBox {
  pointer-events: none;

  span {
    display: none;
  }
}
</style>
